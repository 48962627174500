import React from "react";
import PropTypes from "prop-types";

export default class VisibilityWatcher extends React.Component {

  componentDidMount() {
    document.addEventListener("visibilitychange", this.onDocumentVisibilityChange);
  }

  componentWillUnmount() {
    document.removeEventListener("visibilitychange", this.onDocumentVisibilityChange);
  }

  onDocumentVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      this.props.onResume && this.props.onResume();
    } else {
      this.props.onPause && this.props.onPause();
    }
  };

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

VisibilityWatcher.propTypes = {
  onResume: PropTypes.func,
  onPause: PropTypes.func,
};