import PhotolabTaskBuilder from "../PhotolabTaskBuilder";
import PhotolabTaskCollageMethod from "../PhotolabTaskCollageMethod";
import PhotolabTaskImageUrl from "../PhotolabTaskImageUrl";
import {defaultHandlerCatch, defaultHandlerResolver, photolabTask} from "./helpers";

function templateTask(processing, creative) {
  const maskFileUrl = processing.getFile("mask_0") ? processing.getFile("mask_0").url : "";
  const taskConfig = new PhotolabTaskBuilder()
    .setLanguage(processing.language)
    .addMethod(new PhotolabTaskCollageMethod("gender_classifier"))
    .addImage(new PhotolabTaskImageUrl(processing.getFile(0).url, "", 0, 0, maskFileUrl))
    .build();
  
  creative.setTaskConfig("template", taskConfig);

  return photolabTask(creative, "template", taskConfig);
}

/**
 * @param {Processing} processing
 * @param {Creative} creative
 */
export default (processing, creative) => {
  return new Promise((resolve, reject) => {
    templateTask(processing, creative)
      .then((taskResult) => {
        creative.markAsProcessed(taskResult.gender.value);
      })
      .then(defaultHandlerResolver(creative, resolve))
      .catch(defaultHandlerCatch(creative, reject));
  });
}
