import genderHandler from "./gender.handler";
import comboHandler from "./combo.handler";

export const handlersNames = {
  GENDER: "gender",
  COMBO: "combo",
};

const handlersMap = {
  [handlersNames.GENDER]: genderHandler,
  [handlersNames.COMBO]: comboHandler,
};

export function getHandlerByName(name) {
  return handlersMap[name] || null;
}