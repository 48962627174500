import React from "react";
import AppContext from "../contexts/AppContext";

export default class TestInstanceToast extends React.Component {

  componentDidMount() {
    this.show();

    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        this.show();
      }
    });
  }

  show = () => {
    if (!window.appConfig.isTestInstance) {
      return;
    }

    this.context.showToast({
      message: new URL(window.location.href).hostname,
      delay: 2000,
    });
  };

  render() {
    return <React.Fragment />;
  }
}

TestInstanceToast.contextType = AppContext;