import {urlencode} from "./text";
import {isWebviewBuild} from "./config.utils";
import IntervalQueue from "./IntervalQueue";

let lastCallbackId = 0;

export const webviewAppIds = {
  photolabFree: 1,
  photolabProHD: 2,
  photolabProIos: 3,
  photolabHuawei: 7,
  newprofilepic: 33,
  toonme: 18,
  toonmeHuawei: 19,
  tooncoin: 34,
}

export const shareProvidersIds = {
  save: 0,
  instagram: 1,
  facebook: 2,
  whatsapp: 3,
  facebookMessenger: 5,
  telegram: 6,
  email: 7,
  sms: 8,
  all: 9,
  snapchat: 11,
  tiktok: 12,
  allSystem: 13,
}

const installedPackagesMapAndroid = {
  "com.facebook.orca": "facebookMessenger",
  "com.facebook.katana": "facebook",
  "com.instagram.android": "instagram",
  "com.whatsapp": "whatsapp",
  "org.telegram.messenger": "telegram",
  "com.snapchat.android": "snapchat",
  "com.zhiliaoapp.musically": "tiktok",
};

const installedPackagesMapIos = {
  "fb-messenger://": "facebookMessenger",
  "fb://": "facebook",
  "instagram://": "instagram",
  "whatsapp://": "whatsapp",
  "telegram://": "telegram",
  "snapchat://": "snapchat",
  "tiktoksharesdk://": "tiktok",
};

function createWebviewCallbackFunc(cb) {
  const funcName = "webviewFuncCallback_" + lastCallbackId;
  lastCallbackId++;

  window[funcName] = function() {
    if (cb) {
      cb(...arguments);
    }

    delete window[funcName];
  };

  return funcName;
}

export function webviewBuildCallURL(func, params) {
  const queryPairs = [];

  Object.keys(params).forEach((key) => {
    if (typeof params[key] !== "undefined") {
      queryPairs.push(`${key}=${params[key]}`);
    }
  });

  return `callback:${func}?${queryPairs.join("&")}`;
}

let webviewCallQueue = new IntervalQueue(500);
webviewCallQueue.onRun((data) => {
  console.log("webviewCall", data);
  window.location.href = data;
});

export function webviewCall(func, params) {
  webviewCallQueue.push(webviewBuildCallURL(func, params));
}

export function webviewCallWithCallback(func, params, cb) {
  params.func = createWebviewCallbackFunc(cb);

  webviewCall(func, params);
}

// ---

export function webviewOpenBrowser(url) {
  webviewCall("openblank", {url: urlencode(url)});
}

// ---

export function webviewShare(params, cb) {
  webviewCallWithCallback("nativeShare", params, cb);
}

export function webviewRateApp(func) {
  webviewCallWithCallback("rateApp", func);
}

// ---

export function webviewCheckInstalledApps(cb) {
  if (window.clientConfig.isWebviewIOS) {
    webviewCheckInstalledAppsIos(cb);
  } else {
    webviewCheckInstalledAppsAndroid(cb);
  }
}

function webviewCheckInstalledAppsIos(cb) {
  const packages = Object.keys(installedPackagesMapIos)
    .map((item) => encodeURIComponent(item))
    .join(",");

  webviewCallWithCallback("nativeInstalledApps", {packages: `[${packages}]`}, function(result) {
    result = JSON.parse(result);

    const installedApps = {};
    Object.keys(result.packages).forEach((key) => {
      installedApps[installedPackagesMapIos[key]] = result.packages[key] === 1;
    });

    cb(installedApps);
  });
}

function webviewCheckInstalledAppsAndroid(cb) {
  const packages = Object.keys(installedPackagesMapAndroid).join(",");

  webviewCallWithCallback("nativeInstalledApps", {packages: `[${packages}]`}, function(result) {
    const installedApps = {};
    Object.keys(result.packages).forEach((key) => {
      installedApps[installedPackagesMapAndroid[key]] = result.packages[key] === 1;
    });

    cb(installedApps);
  });
}

export function webviewPreloadAd() {
  webviewCall("preloadAd", {});
}

export function webviewShowAd(adShownCb, adClosedCb) {
  webviewCall("showAd", {
    adShown: createWebviewCallbackFunc(adShownCb),
    adClosed: createWebviewCallbackFunc(adClosedCb),
  });
}

// subscription
export function webviewInApp(id) {
  webviewCall("inapp", {id});
}

// inapp
export function webviewConsumable(id) {
  webviewCall("consumable", {id});
}

export function webviewRestore() {
  webviewCall("restore", {});
}

export function webviewShowBanner(placement, closedCb, data = {}) {
  const closeParamKey = isWebviewBuild(0, 281) ? "onClose" : "onClosed";

  webviewCall("banner", {
    placement,
    [closeParamKey]: createWebviewCallbackFunc(closedCb),
    ...data
  });
}

export function webviewOnPageFinished() {
  webviewCall("onPageFinished", {});
}

export function webviewGetUserIds() {
  webviewCall("getUserIds", {func: "onGetUserIdsCallback"});
}

export function webviewAnalyticsEvent(eventName, eventParams = [], data = null) {
  let callbackParamsString = "event=" + eventName;

  for (let i = 0; i < 6; i++) {
    callbackParamsString += `&value${i + 1}=` + encodeURIComponent(eventParams.length > i ? eventParams[i] : "");
  }

  if (data != null) {
    callbackParamsString += "&data=" + encodeURIComponent(JSON.stringify(data));
  }

  if (window.appConfig.isDebug) {
    console.log("webviewAnalyticsEvent " + eventName, eventParams, data, callbackParamsString);
  }

  if (window.clientConfig.isWebview) {
    webviewCallByIframe("callback:nativeAnalyticsEvent?" + callbackParamsString);
  }
}

function webviewCallByIframe(url) {
  const WVJBIframe = document.createElement("iframe");
  WVJBIframe.style.display = "none";
  WVJBIframe.src = url;
  document.documentElement.appendChild(WVJBIframe);
  setTimeout(function() {
    document.documentElement.removeChild(WVJBIframe);
  }, 0);
}

// callback:preloadRewardedAd?unit_id=&onComplete=
// - принимать параметры: unit_id (какой предзагружать)
// - принимать параметр onComplete(0/1) - js-функция, которую дернем по окончании загрузки со значением успешно или нет
export function webviewPreloadRewardedAd(unitId, cb) {
  webviewCall("preloadRewardedAd", {
    unit_id: encodeURIComponent(unitId),
    onComplete: createWebviewCallbackFunc(cb),
  });
}

// callback:showRewardedAd?unit_id=&adShown=&adClosed=
// - принимать параметры: unit_id (какой показывать)
// - принимать параметр adShown() - js-функция, которую дернем при показе рекламы
// - принимать параметр adClosed(0/1) - js-функция, которую дернем при закрытии рекламы со значением "досмотрели ли до конца"
export function webviewShowRewardedAd(unitId, adShownCbFuncName, adClosedFuncName) {
  webviewCall("showRewardedAd", {
    unit_id: encodeURIComponent(unitId),
    adShown: adShownCbFuncName,
    adClosed: adClosedFuncName,
  });
}

// callback:isRewardedAdPreloaded?unit_id=&func=
// - принимать параметры: unit_id (состояние которого запрашивается)
// - принимать параметр func(0/1) - возвращать состояние загрузки (0/1 = не загружено/загружено)
export function webviewIsRewardedAdPreloaded(unitId) {
  return new Promise((resolve) => {
    webviewCall("isRewardedAdPreloaded", {
      unit_id: encodeURIComponent(unitId),
      func: createWebviewCallbackFunc((data) => resolve(("" + data) === "1")),
    });
  });
}

export function mapSelectedImageItem(item) {
  return {
    url: item.image_url.replace("http://", "https://"),
    rect: item.crop,
    flip: item.flip,
    rotation: item.rotation,
  };
}