import React from "react";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import ErrorView from "./ErrorView";
import {withRouter} from "react-router";
// import * as Sentry from "@sentry/react";

class ErrorBoundary extends React.Component {

  state = {
    error: null,
  }

  static getDerivedStateFromError(error) {
    return {error};
  }

  componentDidCatch(error, errorInfo) {
    hitEvent(hits.COMPONENT_ERROR, 1, true, 0);
    logEvent(userEvents.COMPONENT_ERROR, {
      message: error.message,
      stack: errorInfo.componentStack,
      url: window.location.href,
    });

    // Sentry.captureException(error);
  }

  handleErrorButtonClick = () => {
    const redirectUrl = new URL(window.location.href);
    redirectUrl.pathname = "/";
    redirectUrl.search = "";

    if (window.clientConfig.isWebview) {
      Object.keys(window.clientConfig.webviewParams).forEach((key) => {
        redirectUrl.searchParams.append(key, window.clientConfig.webviewParams[key]);
      });
    }

    window.location.href = redirectUrl.toString();
  };

  render() {
    return this.state.error
      ? <ErrorView error={this.state.error} onButtonClick={this.handleErrorButtonClick} />
      : this.props.children;
  }
}

export default withRouter(ErrorBoundary);