/* eslint-disable no-extend-native */

Array.prototype.includes = function(needle) {
  return this.indexOf(needle) >= 0;
};

Array.prototype.findIndex = function(callback) {
  if (this === null) {
    throw new TypeError("Array.prototype.findIndex called on null or undefined");
  } else if (typeof callback !== "function") {
    throw new TypeError("callback must be a function");
  }

  const list = Object(this);
  const length = list.length >>> 0;
  const thisArg = arguments[1];

  for (let i = 0; i < length; i++) {
    let element = list[i];
    if (callback.call(thisArg, element, i, list)) {
      return i;
    }
  }

  return -1;
};

Array.prototype.find = function(callback) {
  if (this === null) {
    throw new TypeError("Array.prototype.find called on null or undefined");
  } else if (typeof callback !== "function") {
    throw new TypeError("callback must be a function");
  }

  const index = this.findIndex(callback);
  if (index >= 0) {
    return this[index];
  }

  return undefined;
};

Array.prototype.isEmpty = function() {
  return this.length === 0;
};

Array.prototype.isNotEmpty = function() {
  return !this.isEmpty();
};

Array.prototype.randomIndex = function() {
  return this.isNotEmpty()
    ? Math.floor(this.length * Math.random())
    : -1;
};

Array.prototype.random = function() {
  return this.isNotEmpty()
    ? this[this.randomIndex()]
    : null;
};

Array.prototype.count = function(predicate, thisArg) {
  return this.filter(predicate, thisArg).length;
};

Array.prototype.first = function() {
  return this.length > 0 ? this[0] : null;
};

Array.prototype.last = function() {
  return this.length > 0 ? this[this.length - 1] : null;
};

Array.prototype.shuffle = function() {
  const source = this.slice();
  const dest = [];

  while (source.length > 0) {
    const items = source.splice(source.randomIndex(), 1);
    dest.push(items[0]);
  }

  return dest;
};

Array.prototype.max = function(predicate) {
  let max = -Infinity;
  for (let i = 0; i < this.length; i++) {
    const itemMax = predicate(this[i]);
    if (itemMax > max) {
      max = itemMax;
    }
  }

  return max;
};

Array.prototype.min = function(predicate) {
  let min = Infinity;
  for (let i = 0; i < this.length; i++) {
    const itemMin = predicate(this[i]);
    if (itemMin < min) {
      min = itemMin;
    }
  }

  return min;
};