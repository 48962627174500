module.exports = {
    "index_title": "Makeover AI",
    "index_subtitle": "Fantastic. Unreal. Creatively different<br />Explore the new you, in dozens of new looks and dramatic makeovers created by AI.",

    "upload_choose_photo_button": "Choose photo",

    "result_title": "Results",
    "result_notice_1": "You look amazing! <span class=\"text-decoration\">Share to inspire others</span>",
    "result_notice_2": "Time to show off your makeover! <span class=\"text-decoration\">Tap to share</span>",
    "result_notice_3": "Stunning looks are for sharing. <span class=\"text-decoration\">Tap if you agree</span>",
    "result_notice_4": "Don’t keep your look to yourself, <span class=\"text-decoration\">share it now!</span>",

    "try_another_photo": "Try another photo",
    "retry": "Retry",
    "next": "next",

    "photochooser_title": "Upload your photos",
    "photochooser_photos_count": "Uploaded: <span>{{num}} photos</span>",
    "photochooser_photos_error": "(Some photos cannot be used. Tap to learn more)",
    "photochooser_delete_photo": "Select a photo to delete",
    "photochooser_rule_1": "<span>At least 2 photos</span>",
    "photochooser_rule_2": "All the photos are of <span>the same person</span>",
    "photochooser_rule_3": "<span>Only one person</span> in a photo",

    "button__select_photos": "select photos",
    "button__select_more": "select more",
    "button__add_more": "Add more",
    "button__proceed": "proceed",
    "button__select_to_delete": "Select a photo to delete",
    "button__tap_to_delete": "Tap a photo to delete",
    "button__cancel": "cancel",
    "button__retry": "retry",
    "button__try_again": "try again",
    "button__delete": "delete",
    "button__ok": "OK",

    "download": "Download",
    //"save_and_share": "Save & Share",

    "error__default_message": "Something went wrong. Try again.",
    "error_title": "Something went wrong!",
    "error__api_code_1": "Internal error",
    "error__api_code_2": "Incorrect params",
    "error__api_code_3": "Processing failure",
    "error__api_code_401": "Unauthorized request",
    "error__api_code_404": "Not found",
    "error__api_code_410": "The requested content is deleted",
    "error__api_code_415": "Sorry, this file format is not supported. Please upload an image file (JPEG or PNG).",

    "error_overload": "We are terrible sorry, but our servers experience too much of a load currently due to skyrocketing app downloads. Please, come back a little later. We are working hard to fix everything ASAP.",

    "watermark_screen__title": "",
    "draggable_watermark_tooltip": "<span>Tap&Drag</span> to move <br>& delete watermark",
    "watermark_removal_modal__title": "Yep, you can remove the watermark, but…",
    "watermark_removal_modal__text1": "We’d like you to keep it and let your friends know about our app. Don’t feel like keeping it? Spread the hash-word about {{app_name}}.",
    "watermark_removal_modal__text2": "The entire amount of money raised goes to a charity fund!",
    "watermark_removal_modal__notice": "<span class=\"text-bold\">TIP:</span> you can just drag the watermark to the position you want.",
    "watermark_removal_modal__choice": "Your choice?",
    "watermark_removal_modal__confirm": "Remove watermark",
    "watermark_removal_modal__cancel": "Spread the good!",
    "watermark_removal_modal__close": "Close",

    "processing_text_1": "AI is looking for a stunning look for you 🔍",
    "processing_text_2": "Any idea what it’s gonna look like? 🙈",
    "processing_text_3": "Just wait for it… 🪄",
    "processing_text_4": "AI’s fantasy is coming true 🧚",

    "subscribe_subscription_terms": "Renews automatically. Cancel any time.&nbsp;<span>Terms</span>",

    "cancel": "cancel",

    "subscribe_banner__text": "<span class=\"text-bold\">Get <span class=\"text-yellow\">unlimited</span> access to <span class=\"text-yellow\">premium</span> artworks.</span>",
    "subscribe_banner__subtext": "Enjoy 3 days for FREE, then {{price}}/week",

    "purchase_banner__text": "<span class=\"text-bold\">Get 100+ <span class=\"text-yellow\">premium</span> artworks</span>",
    "purchase_banner__subtext": "Enjoy more variations with {{price}} one-time purchase",

    "rewarded_ad_banner__text": "<span class=\"text-bold text-yellow\">Keep looking</span>",
    "rewarded_ad_banner__subtext": "Unlock other results by watching a short sponsored video.",
};

