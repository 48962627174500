import React from "react";
import {withRouter} from "react-router";
import i18n from "../i18n";
import processingManager from "../photolab/ProcessingManager";
import {generatePath} from "react-router";
import routes from "../routes";

const LOADING_TEXT_INTERVAL = 3000;

class Loading extends React.Component {

  constructor(props) {
    super(props);

    this.texts = [
      "processing_text_1",
      "processing_text_2",
      "processing_text_3",
      "processing_text_4",
    ];

    this.state = {
      imageIsLoaded: false,
      //textIndex: Math.floor(Math.random() * (this.texts.length - 1)),
      textIndex: 0,
    };

    this.updateTextIndexTimer = null;

    this.image = new Image();
    this.image.onload = () => this.setState({imageIsLoaded: true});
  }

  componentDidMount() {
    if (this.props.image) {
      this.image.src = this.props.image;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.image !== this.props.image) {
      this.setState({imageIsLoaded: false}, () => {
        if (this.props.image) {
          this.image.src = this.props.image;
        }
      });
    }

    if (prevProps.isHidden !== this.props.isHidden) {
      if (this.props.isHidden) {
        clearInterval(this.updateTextIndexTimer);
        this.updateTextIndex();
      } else {
        this.updateTextIndexTimer = setInterval(this.updateTextIndex, LOADING_TEXT_INTERVAL);
      }
    }
  }

  componentWillUnmount() {
    this.image.onload = () => {};
    clearInterval(this.updateTextIndexTimer);
  }

  updateTextIndex = () => {
    if (this.state.textIndex === this.texts.length - 1) {
      this.setState({textIndex: 0});
    } else {
      this.setState({textIndex: this.state.textIndex + 1});
    }
  }

  handleCancelButtonClick = () => {
    processingManager.clear();

    this.props.history.replace(generatePath(routes.INDEX));
  }

  render() {
    return <main className="loader" hidden={this.props.isHidden}>
      <button
        className="loader-cancel-button"
        onClick={this.handleCancelButtonClick}>
        {i18n.t("cancel")}
      </button>

      <div className="spinner-container">
        <div className="triple-spinner">
          <div className="triple-spinner2" />
        </div>

        {this.props.images && this.props.images.length > 0 && <div className="triple-spinner-avatars">
          <div className="triple-spinner-avatar-1">
            <img src={this.props.images[0]} alt="" />
          </div>
        </div>}

      </div>
      <div className="loader-text-container" hidden={this.props.hideText}>
        {/*<p className="loader-tip" dangerouslySetInnerHTML={{__html: i18n.t("processing_tip", {item: this.state.textIndex + 1, total: this.texts.length})}} />*/}
        <p dangerouslySetInnerHTML={{__html: i18n.t(this.texts[this.state.textIndex]) || ""}} />
      </div>
    </main>;
  }
}

export default withRouter(Loading);
