import React from "react";
import VisibilityWatcher from "./VisibilityWatcher";

export default class WindowSizeWatcher extends React.Component {

  windowHeight = 0;

  constructor(props) {
    super(props);
    this.updateWindowHeight();
    this.onResume();
  }

  onResume = () => {
    this.windowHeightTimer = setInterval(this.updateWindowHeight, 1000/5);
  };

  onPause = () => {
    clearInterval(this.windowHeightTimer);
  };

  updateWindowHeight = () => {
    if (this.windowHeight !== window.innerHeight) {
      this.windowHeight = window.innerHeight;
      document.documentElement.style.setProperty("--window-inner-height", this.windowHeight + "px");
    }
  };

  render() {
    return <VisibilityWatcher
      onResume={this.onResume}
      onPause={this.onPause}
    />;
  }
}