import React from "react";
import {withRouter} from "react-router";

class RouteChangeListener extends React.Component {

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.onChanged && this.props.onChanged(this.props.location.pathname, prevProps.location.pathname);
    }
  }

  render() {
    return <React.Fragment />;
  }
}

export default withRouter(RouteChangeListener);