export default function IntervalQueue(interval) {
  const queue = [];
  let runCallback = null;
  let timer = null;
  let nextTimeMs = 0;

  function schedule() {
    if (queue.length > 0) {
      clearTimeout(timer);
      timer = setTimeout(
        dispatch,
        Math.max(0, nextTimeMs - Date.now())
      );
    }
  }

  function dispatch() {
    const data = queue.shift();
    if (data !== undefined) {
      runCallback(data);
      nextTimeMs = Date.now() + interval;
    }

    schedule();
  }

  this.push = function(callUrl) {
    queue.push(callUrl);
    schedule();
  };

  this.onRun = (callback) => {
    runCallback = callback;
  };
}