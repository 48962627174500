function allSettled(promises) {
  const mappedPromises = promises.map((promise) => {
    return promise
      .then((value) => ({status: "fulfilled", value}))
      .catch((reason) => ({status: "rejected", reason}))
  });

  return Promise.all(mappedPromises);
}

Promise.allSettled = Promise.allSettled || allSettled;