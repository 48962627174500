let userClientData = {};

function defaultHandler(res) {
  if (res.ok) {
    return res.json();
  } else {
    const error = new Error("InappResponseError");
    error.name = "InappResponseError";
    error.response = res.clone();

    throw error;
  }
}

function networkErrorHandler(err) {
  const error = new Error("Network error");
  error.name = "NetworkError";
  error.parentError = err;

  throw error;
}

function defaultErrorHandler(err) {
  throw err;
}

function setUserClientData(data) {
  userClientData = data;
}

function getFullPath(path = "", basePath) {
  const url = new URL((basePath || window.appConfig.paths.inapps) + path);

  for (let key in userClientData) {
    url.searchParams.append(key, userClientData[key]);
  }

  return url.toString();
}

function fetchList() {
  return fetch(getFullPath(""), {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      action: "list",
    }),
  })
    .catch(networkErrorHandler)
    .then(defaultHandler)
    .catch(defaultErrorHandler);
}

function consume(purchaseToken, amount) {
  return fetch(getFullPath(""), {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      action: "consume",
      purchase_token: purchaseToken,
      amount,
    }),
  })
    .catch(networkErrorHandler)
    .then(defaultHandler)
    .catch(defaultErrorHandler);
}

export default {
  setUserClientData,
  fetchList,
  consume,
};